import React, { Component, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { BsArrowRight  } from "react-icons/bs";
import moment from 'moment';

class Agency extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chats: [],
            users: [],
            agencies: [],
            isLoading: false,
            userId: 0,
            startDate: moment().subtract(29, 'days'),
            endDate: moment(),
        };
        this.handleAgencyChange = this.handleAgencyChange.bind(this);
        this.updateAutoChat = this.updateAutoChat.bind(this);
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.exportToCSV = this.exportToCSV.bind(this);
    }
    componentDidMount = async () => {
        var agencyId = localStorage.getItem('agencyId');
        const userId = localStorage.getItem('userId');
        this.setState({isLoading: true});
        if(userId == "" || !userId)
        {
            this.props.history.push('/home');
        }else{
            const chatResponse = await fetch('https://api.leadmanagertraining.com/api/chat/getautochatdata?agencyid=' + agencyId, { 
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const chats = await chatResponse.json();
            const agenctResp = await fetch('https://api.leadmanagertraining.com/api/ezllagency', { 
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const agencies = await agenctResp.json();
            const userResponse = await fetch('https://api.leadmanagertraining.com/api/ezllusers', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const users = await userResponse.json();
            this.setState({chats: chats, agencies: agencies, isLoading: false, userId: userId, users: users.filter(u => u.agencyId == agencyId)});
        }
    }
    componentDidUpdate(prevProps){
       
    }

    handleAgencyChange = async (e) => {
        const chatResponse = await fetch('https://api.leadmanagertraining.com/api/chat/getautochatdata?agencyid=' + e.target.value, { 
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const chats = await chatResponse.json();
            this.setState({chats: chats});
    }
    handleDateRangeChange = async () => {
       console.log(moment(this.state.startDate).format('MM/DD/YYYY') + " - " + this.state.endDate);
       var chats = this.state.chats.filter(d => moment(d.dts).format('MM/DD/YYYY') == moment(this.state.startDate).format('MM/DD/YYYY'));
       this.setState({chats: chats});
    }
    updateAutoChat(status, userId, chatId){
        try {
            fetch('https://api.leadmanagertraining.com/api/chat/updatechat?status=' + status + '&userId=' + userId + '&chatId=' + chatId, { 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        }
        catch(e) {
            console.log('Sending message failed.', e);
        }
    }
    exportToCSV = (e) => {
        e.preventDefault();
        var data, filename, link;
        data = this.state.chats;
        var csv = this.convertArrayOfObjectsToCSV(data);
        if (csv == null)
          return;
    
        filename = 'EZLiveLeads_Chat Report.csv';
    
        var blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});
    
        if (navigator.msSaveBlob)
        { // IE 10+
          navigator.msSaveBlob(blob, filename)
        }
        else
        {
          var link = document.createElement("a");
          if (link.download !== undefined)
          {
            // feature detection, Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style = "visibility:hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }
    
    convertArrayOfObjectsToCSV = (args) => {
        var result, ctr, keys, columnDelimiter, lineDelimiter, data;
    
        data = args || null;
        if (data == null || !data.length) {
            return null;
        }
    
        columnDelimiter = args.columnDelimiter || ',';
        lineDelimiter = args.lineDelimiter || '\n';
    
        keys = Object.keys(data[0]);
    
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
    
        data.forEach(function(item) {
            ctr = 0;
            keys.forEach(function(key) {
                if (ctr > 0) result += columnDelimiter;
                if(key == 'notes' && item[key] != null){
                    var val = item[key].toString();
                    val = val.replace(/[\n\r]+/g, ' ');
                    val = val.replaceAll(',', ' ');
                    result += val;
                }
                else{
                    result += item[key] == null ? '' : item[key];
                }
                ctr++;
            });
            result += lineDelimiter;
        });
    
        return result;
    };
    render() {  
        
        return (
            <div style={{marginLeft: 10, marginTop: 10, marginRight: 10, width: '100%'}}>
                <Card>
                    <Card.Header>
                        CHATS
                        <Button size="sm" variant="primary" onClick={this.exportToCSV} style={{position: 'absolute', right: 15 }}>Export</Button>
                    </Card.Header>
                    <Card.Body>
                        <Row style={{paddingTop: 10, paddingBottom: 10}} style={{display: 'none'}}>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Choose Agency</Form.Label>
                                    <Form.Control  as="select" onChange={this.handleAgencyChange} className="form-select">
                                        <option value=''></option>
                                        {this.state.agencies.filter(i => i.inactive == false).map(a => 
                                            <option key={a.id} value={a.id}>{a.name}</option>
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        {this.state.isLoading == false ? 
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" style={{display: 'none'}}>
                                    <Form.Label>Date Range</Form.Label>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <Form.Control type="date" placeholder="Start Date" style={{width: '200px'}} defaultValue={this.state.startDate} onChange={(e) => this.setState({startDate: e.target.value})} />
                                        <BsArrowRight style={{marginTop: 10}} />
                                        <Form.Control type="date" placeholder="End Date" style={{width: '200px', marginRight: 10}} defaultValue={this.state.endDate} onChange={(e) => this.setState({endDate: e.target.value})} />
                                        <Button size="sm" variant="primary" onClick={this.handleDateRangeChange}>Go</Button>
                                    </div>
                                </Form.Group>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Address</th>
                                            <th>City</th>
                                            <th>State</th>
                                            <th>Zip</th>
                                            <th>Phone</th>
                                            <th>Email</th>
                                            <th>Notes</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Transferred To</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.chats.map(u => 
                                                <tr key={u.id}>
                                                    <td>
                                                        {u.firstName}
                                                    </td>
                                                    <td>
                                                        {u.lastName}
                                                    </td>
                                                    <td>
                                                        {u.address1}
                                                    </td>
                                                    <td>
                                                        {u.city}
                                                    </td>
                                                    <td>
                                                        {u.state}
                                                    </td>
                                                    <td>
                                                        {u.zip}
                                                    </td>
                                                    <td>
                                                        {u.primaryPhone}
                                                    </td>
                                                    <td>
                                                        {u.email}
                                                    </td>
                                                    <td>
                                                        {u.notes}
                                                    </td>
                                                    <td>
                                                        {moment(u.dts).format('MM/DD/YYYY h:mm a')}
                                                    </td>
                                                    <td>
                                                        <Form.Control className="form-select" as="select" placeholder="Select Status" onChange={(e) => this.updateAutoChat(e.target.value, u.transferUserId, u.chatId)} defaultValue={u.status}>
                                                            <option></option>
                                                            <option>Quoted</option>
                                                            <option>One-Call-Close</option>
                                                            <option>Failed Transfer</option>
                                                            <option>Did Not Qualify</option>
                                                            <option>Sold</option>
                                                            <option>Missed Call</option>
                                                            <option>Not Interested</option>
                                                            <option>Return Lead</option>
                                                        </Form.Control>
                                                    </td>
                                                    <td>
                                                    <Form.Control className="form-select" as="select" placeholder="Select User" onChange={(e) => this.updateAutoChat(u.status, e.target.value, u.chatId)} defaultValue={u.transferUserId}>
                                                        <option></option>
                                                        {
                                                            this.state.users.filter(i => i.status != 4).map(user => 
                                                                <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                                            
                                                            )
                                                        }
                                                    </Form.Control>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        :
                        <Spinner animation="border" variant="primary" />
                        }
                    </Card.Body>
                </Card>
            </div>
        );
    }
}
export default Agency;