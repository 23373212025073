import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BsCalendarCheck } from 'react-icons/bs';

class LiveCallTransfers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        };
    }

    render() {
        return (
            <div style={{paddingTop: 60, width: '60%', marginLeft: 30}}>
                <Row style={{fontSize: 29}}>
                    <Col sm={12}>
                        Industries that we serve:
                    </Col>
                </Row>
                <Row style={{textAlign: 'center', fontSize: 29}}>
                    <Col sm={3} style={{borderRight: 'solid black 1px'}}>
                        Insurance 
                    </Col>
                    <Col sm={3} style={{borderRight: 'solid black 1px'}}>
                        Solar
                    </Col>
                    <Col sm={3} style={{borderRight: 'solid black 1px'}}>
                        Mortgage
                    </Col>
                    <Col sm={3}>
                        Health
                    </Col>
                </Row>
                <Row style={{paddingTop: 20}}>
                    <Col sm={12}>
                        <p style={{fontSize: 29, paddingTop: 10}}>
                            Live Call transfers have proven to be the top performing lead source for every industry that we serve.  Businesses are constantly struggling to maintain the quote volume that they need in order to CRUSH their sales goals.  Unlike most marketing strategies, Live Call Transfers don’t require a ramp up time.  They will immediately lift your sales volume if the prospect is qualified and is in a position to buy your product or services.  This marketing strategy is absolutely scalable as it allows your sales agent to focus on what they do best…..write business.
                            We have partnered with the largest and most respected aggregators to drive TCPA compliant leads with JORNAYA leadiD Validation to our In-house Telemarketing Team.  Once our Telemarketing Agent makes contact and qualifies the prospect, they will transfer the prospect directly to you!
                            Jump on our Calendar via the Link Below to schedule a consultation and find out if we are a good fit!
                        </p>
                        <p style={{textAlign: 'center'}}>
                            <a className='btn btn-outline-secondary btn-lg rounded-pill' href='https://CALENDAR-APPOINTMENT.as.me/EZ-LIVE-LEADS' target="_blank" style={{color: '#000', backgroundColor: '#f1f8e9'}}>
                                <BsCalendarCheck /> SCHEDULE YOUR CONSULTATION
                            </a>
                        </p>
                    </Col>
                </Row>
                
            </div>
        );
    }
}
export default LiveCallTransfers;