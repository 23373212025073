import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import { BsCalendarCheck } from 'react-icons/bs';

class Products extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        };
    }

    render() {
        return (
            <div className="container">
                <h2 style={{textAlign: 'center'}}>Products</h2>
                <Card border="success" style={{marginBottom: 20}}>
                    <Card.Header><h3>Web Leads</h3></Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={12}>
                                <p>
                                    <h4>Live Web Leads</h4>
                                    <div>Our Live Web Leads are Always sourced from the most vetted and trusted aggregators and publishers in the industry.  Each Live Web Lead is highly filtered and sourced via SEO or SEM traffic with a token proving client opt-in.  We do our best to bring high quality leads with the highest contact rate and ROI. (Normal Font, No Bold)</div>
                                </p>
                                <p>
                                    <h4>Overnight Web Leads</h4>
                                    <div>If Live Web Leads are out of your budget yet you want strong data to support one of our Telemarketing Campaigns or one of your internal telemarketing campaigns then Overnight Leads a great alternative.  We are able to bid on Leads that are generated at off peak hours and then push the leads to you the next morning.  The leads are a few hours older but perform very well.</div>
                                </p>
                                <p>
                                    <h4>0 to 30 Day Aged Web Leads</h4> 
                                    <div>In addition to Live Web Leads, Aged Leads are a GREAT way to support a Telemarketing Campaign with EZ Marketing.  A full time telemarketer requires a lot of data to call on while we are building your backlog of Live Web Leads.  The contact rate on our Aged Data is consistent with that of Live Web Leads yet the transfer rate is lower because the prospect lacks the same intent to buy as when they first completed the web form.  In spite of low intent, our Telemarketing campaigns perform very well when paired with our Live Web Leads.</div>
                                </p>
                                <p>
                                    <h4>30 to 60 Day Aged Web Leads</h4>
                                    <div>EZ Marketing has thousands of Unsold Web Leads that have reached 30 to 60 Days Old.  The contact rate is 2 to 3% lower than that of Live Web Leads and  the transfer and quote rate is typically half that of Live Web Leads.  30 to 60 Day Web Leads should be blended with newer data and never used by itself to support a telemarketing campaign.</div>
                                </p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card border="success">
                    <Card.Header><h3>Telemarketing Campaigns</h3></Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={12}>
                                <p>
                                    For the past 3 Years, EZ Marketing has been developing a team of highly professional Telemarketers out of the Philippines who are hand picked and developed by our team.  We employ each Telemarketer directly which eliminates bad practices and unfair wages that are found in traditional Filipino Call Centers.
                                </p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}
export default Products;