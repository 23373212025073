import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import NavDropdown from 'react-bootstrap/NavDropdown'
import { BsEnvelope } from 'react-icons/bs'

    class Master extends React.Component {
        constructor(props){
            super(props)
            this.state = {
                userId: ''
            }
            this.handleLogout = this.handleLogout.bind();
        }
        componentDidMount() {
            const userId = localStorage.getItem('userId');
            if (userId != 0 && userId != null){
                this.setState({userId: userId});
            }
        }
        handleLogout(){
            localStorage.setItem('userId', '');
            localStorage.setItem('agencyId', '');
            localStorage.setItem('status', '');
        }
        render(){
            const myStyle={
                backgroundImage: "url(https://images6.fanpop.com/image/photos/39400000/green-background-random-39415329-1920-1200.jpg)",
                height:'100vh',
                marginTop:'-70px',
                fontSize:'50px',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              };
            const userId = localStorage.getItem('userId');
            const status = localStorage.getItem('status');
            console.log(this.constructor.name)
            return(
                    <div id="page-container">
                        <Row>
                            <Col> 
                                <Navbar collapseOnSelect expand="lg" style={{backgroundColor: '#fff', borderBottom: 'solid 1px #ccc'}}>
                                    <Navbar.Brand href="#home"><img src="ezliveleads.png" alt="Logo" style={{float: "left", height: 70}} /></Navbar.Brand>
                                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                    <Navbar.Collapse id="responsive-navbar-nav">
                                        <Nav className="me-auto">
                                            <Nav.Link><Link to={'/'} style={{fontSize: '18px', color: '#000'}}>HOME</Link></Nav.Link>
                                            <Nav.Link style={{display:userId == '' || !userId ? 'none' : 'inline'}}><Link to={'/chat'} style={{fontSize: '18px', color: '#000'}}>CHAT</Link></Nav.Link>
                                            <Nav.Link style={{display:status == 3 ? 'inline' : 'none'}}><Link to={'/agency'} style={{fontSize: '18px', color: '#000'}}>CREATE AGENCY</Link></Nav.Link>
                                            <Nav.Link style={{display:status == 2 || status == 3 ? 'inline' : 'none'}}><Link to={'/users'} style={{fontSize: '18px', color: '#000'}}>USERS</Link></Nav.Link>
                                            <Nav.Link style={{display:status == 2 || status == 3 ? 'inline' : 'none'}}><Link to={'/register'} style={{fontSize: '18px', color: '#000'}}>REGISTER USERS</Link></Nav.Link>
                                            <Nav.Link style={{display:status == 2 || status == 3 ? 'inline' : 'none'}}><Link to={'/chatreport'} style={{fontSize: '18px', color: '#000'}}>CHAT REPORT</Link></Nav.Link>
                                            <Nav.Link style={{display:status == 2 ? 'inline' : 'none'}}><Link to={'/leadreports'} style={{fontSize: '18px', color: '#000'}}>LEAD REPORTS</Link></Nav.Link>
                                            <Nav.Link style={{display:status != 3 ? 'inline' : 'none'}}><Link to={'/contact'} style={{fontSize: '18px', color: '#000'}}>CONTACT ADMIN</Link></Nav.Link>
                                            <Nav.Link style={{display:userId == '' || !userId ? 'none' : 'inline'}}><Link to={'/tcpa'} style={{fontSize: '18px', color: '#000'}}>TCPA Handbook</Link></Nav.Link>
                                            <Nav.Link style={{display:status != 3 ? 'inline' : 'none'}}><Link to={'/products'} style={{fontSize: '18px', color: '#000'}}>PRODUCTS</Link></Nav.Link>
                                            <Nav.Link style={{display:userId == '149' ? 'inline' : 'none'}}><Link to={'/upload'} style={{fontSize: '18px', color: '#000'}}>UPLOAD FILES</Link></Nav.Link>
                                            <Nav.Link><Link to={'/Login'} style={{fontSize: '18px', position: 'absolute', right: 0, marginRight: 10}} onClick={() => this.handleLogout()}>{userId == '' || !userId ? 'LOGIN' : 'LOGOUT'}</Link></Nav.Link>
                                        </Nav>
                                    </Navbar.Collapse>
                                </Navbar>
                            </Col>
                        </Row>
                        <div id="content-wrap">
                            {this.props.children}
                        </div>
                    </div>
                    
                // </div>
            );
        }
    }
    export default withRouter(Master);