import React, { Component, createRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { BsFillTrashFill  } from 'react-icons/bs';
import moment from 'moment';

class LeadReports extends Component {
    constructor(props) {
        super(props)
        this.state = {
            agencies: [],
            file: '',
            selectedAgency: '',
            fileLink: '',
            agencyFiles: []
        };

    }
    componentDidMount = async () => {
        var agencyId = localStorage.getItem('agencyId');
        const userId = localStorage.getItem('userId');
        if(userId == "" || !userId)
        {
            this.props.history.push('/home');
        }else{
            const agenctResp = await fetch('https://api.leadmanagertraining.com/api/ezllagency/GetAgencyFiles?agencyId=' + agencyId, { 
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const agencyFiles = await agenctResp.json();
            this.setState({agencyFiles: agencyFiles});
        }
    }
    render() {
        return (
            <div className="container" style={{marginTop: 10}}>
                <Card style={{marginTop: 20}}>
                    <Card.Header>
                        LEAD REPORTS
                    </Card.Header>
                    <Card.Body>
                        <Row style={{paddingTop: 10, paddingBottom: 10}}>
                            <Col md={12}>
                                <table style={{width: '50%', border: 'solid 1px #ccc'}}>
                                    <thead style={{borderBottom: 'solid 1px #ccc', fontWeight: 'bold'}}>
                                        <tr>
                                            <td style={{borderRight: 'solid 1px #ccc'}}>File</td>
                                            <td style={{borderRight: 'solid 1px #ccc'}}>Upload Date</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.agencyFiles.map(f =>
                                            <tr key={f.fileName} style={{borderBottom: 'solid 1px #ccc'}}>
                                                <td style={{borderRight: 'solid 1px #ccc'}}><a href={f.link}>{f.fileName}</a></td>
                                                <td style={{borderRight: 'solid 1px #ccc'}}>{moment(f.uploadDate).format('MM/DD/YYYY')}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}
export default LeadReports;